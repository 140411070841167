<template>
    <div class="results-container">
        <b-table class="search-results" 
          striped 
          default-sort-direction="asc" 
          default-sort="name" pack="fa" 
          sort-icon="arrow-down" 
          sort-icon-size="is-small"
          paginated
          per-page="5"
          :data="data"
        >
          <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column" >
                <template
                    v-if="column.searchable && !column.numeric"
                    slot="searchable"
                    slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        style="padding-left: 0px;"
                        placeholder="Search..."
                        size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row[column.field] }}
                    <div v-if="column.field === 'add'">
                      <button class='button mini-button is-primary is-outlined is-success' 
                        :id='props.index' 
                        alt="Invite" 
                        v-on:click="sendInvite(props.row.id, props.index)"
                      >
                        <b-icon pack="fa" icon="user-plus">
                        </b-icon>
                      </button>
                    </div>
                </template>
            </b-table-column>
          </template>
          <p v-if='this.message != ""' class="is-error error-message">{{ this.message }} <router-link :to="{ name: 'MyInvite', params: {mail: this.q} }"><button class='button mini-button is-primary is-outlined is-success'>Invite by e-mail</button></router-link></p>
          <span v-if='this.emsg != ""' class="is-error error-message">{{ this.emsg }}<br><br></span>
        </b-table>
         
    </div>
</template>
<script>

export default {
    props: {
            q: String
    },
    data() {
      return {
        message: "",
        emsg: "",
        data: [],
        columns: [
          {
              field: 'add',
              label: 'Add',
              width: 60,
          },
          {
              field: 'name',
              label: 'Name',
              width: 100,
              searchable: true,
              sortable: true,
          },
          {
              field: 'email',
              label: 'E-Mail',
              width: 100,
              searchable: true,
          },
          {
              field: 'lock',
              label: 'Lock',
              width: 70,
          },
          {
              field: 'device',
              label: 'Device',
              width: 50,
          }
        ]
      }
    },
    watch: {
      '$props':{
        handler: function (val, oldVal) { 
          console.log('watch', val);
          console.log('watch', oldVal);
          this.message="";
          this.getData();
        },
        deep: true
      }
    },
    created() {
      this.getData();
    },
    methods: {
      getData() {
        requestAnimationFrame(() => {
          var api = process.env.VUE_APP_API;
          var query = this.q;
          console.log("API: " + api);
          console.log("Searching: " + query);
          this.loading = true;

          this.$store.dispatch('auth/search', query).then(
          (response) => {
                  console.log(response);
                  if (response.status == true) {
                      console.log("if loop: ");
                      console.log(response.results.data);
                      this.data = response.results.data;
                  } else {
                    this.message = "No such user..."
                    console.log("No such user");
                    this.data = response.results.data;
                  }
              },
              error => {
                  this.message = "No such user..."
                  this.loading = false;
                  this.data = [];
                  console.log(error);
              }
          );

        });
      },
      sendInvite(uid, row) {
        this.rmsg = "";
        this.emsg = "";
        requestAnimationFrame(() => {
          var api = process.env.VUE_APP_API;
          console.log("API: " + api);
          console.log("Sending invite to: " + uid);
          this.loading = true;

          this.$store.dispatch('auth/send_invite', uid).then(
          (response) => {
              console.log(response.status);
              if (response.status == true) {
                  this.smsg = "Accepted";
                  var invite = document.getElementById(row)
                  invite.innerHTML="<span>Invited</span>";
                  invite.disabled = true;
              } else {
                this.emsg = response.message;
                console.log(response.message);
              }
          },
          error => {
              this.emsg = "Server Error...";
              console.log(error);
          });
          this.loading = false;
        });
      }
    }
}
</script>
<style lang='scss' scoped>
h1 {
    font-weight: bold;
}
.results-container {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 40px;
}
pre {
  font-family: Consolas, monospace;
  color: #000;
  background: #fff;
  border-radius: 2px;
  padding: 15px;
  line-height: 1.5;
  overflow: auto;
}
.search-results {
  padding-top: 30px;
}
table.table {
    table-layout: fixed;
}
.error-message {
  text-align: center;
  color: red;
  margin-bottom: 30px;
}
.mini-button {
  padding: 10px;
  margin-left: 5px;
  height: 15px;
}
</style>